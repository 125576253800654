<template>
  <div class="text-center" >
    <v-dialog
        v-model="progress"
        width="200px"
        scrollable
        v-if="progress"
      >
      <v-card
          color="primary"
          dark
          v-if="progress"
          width="150px"
      >
        <v-card-text>
          Please stand by
        <v-progress-linear
        width="150px"
        indeterminate
        color="white"
        class="mb-0"
        ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      max-width="950px"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
      v-else
    >
      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          Detail Inventory Out
            <v-spacer></v-spacer>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-simple-table>
              <template v-slot:default>
                  <tr>
                    <td class="text-left">
                      Document
                    </td>
                    <td class="text-left" colspan="4">
                      {{data.doc_no}}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      Date
                    </td>
                    <td class="text-left" colspan="4">
                      {{data.entry_date |tgl_sales}}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-left">
                      User
                    </td>
                    <td class="text-left" colspan="4">
                      {{data.entry_by}}
                    </td>
                  </tr>
                  <tr>
                    <td :rowspan="items.length + 2">Transaction Details</td>
                  </tr>
                  <tr>
                    <td>Product</td>
                    <td>Quantity</td>
                    <td>Base Price</td>
                    <td>Sub Total</td>
                  </tr>
                  <tr v-for="item in items" :key="item.id">
                    <td>
                      {{item.product.name}}
                    </td>
                    <td class="text-right">
                      {{item.qty}}
                    </td>
                    <td class="text-right">
                       {{item.product.base_price | numeral('0,0')}}
                    </td>
                    <td class="text-right">
                       {{item.product.base_price * item.qty | numeral('0,0')}}
                    </td>

                  </tr>
                  <tr>
                    <td colspan="4" class="text-center">Total</td>
                    <td class="text-right"> {{total| numeral('0,0')}}</td>
                  </tr>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="css" scoped>
    table {
      border-collapse: collapse;

    }
    table, th, td {
    border: 1px solid #efefef;
    }
    td{
      padding:10px
    }
</style>
<script>
import DetailInventoryMixin from '../../../mixin/DetailInventory'
export default {
  mixins:[DetailInventoryMixin],
   data() {
    return {
      total:0,
    }
  },
  computed: {
    backgroundColor () {
      return `background-color:${this.color} !important;color:white`
    },
  },
  async created () {
    await this.axios.get('v1/inventory/out/details/' + this.data.id)
    .then((ress)=> {
      this.items = ress.data.data
       ress.data.data.forEach(element => {
        this.total = this.total + (parseInt(element.product.base_price) * element.qty)
      });
    })
    .catch((err) => console.log(err.response))
    this.progress = false
  }
}
</script>
