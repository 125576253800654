<template>
  <div class="text-center" >
    <v-dialog
      v-model="dialog_from_input"
      max-width="950px"
      @click:outside="close()"
      @keydown.esc="close()"
      scrollable
    >
        <v-dialog
          v-model="progress"
          width="200px"
          scrollable
        >
        <v-card
            color="primary"
            dark
            v-if="progress"
            width="150px"
        >
          <v-card-text>
            Please stand by
          <v-progress-linear
          width="150px"
          indeterminate
          color="white"
          class="mb-0"
          ></v-progress-linear>
          </v-card-text>
        </v-card>
        </v-dialog>

      <v-card
      tile
      >
        <v-card-title
          class="custom-modal-title"
          :style="backgroundColor"
          primary-title
        >
          {{edit_data ? 'Edit' : 'Create'}} Inventori Out
            <v-spacer></v-spacer>
          <span style="cursor:pointer; margin-right:10px;" @click="openProduct"><v-icon >mdi mdi-clipboard-list-outline</v-icon></span>
          <span @click="close" style="cursor:pointer">x</span>
        </v-card-title>

        <v-card-text>
          <v-container>
               <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                    >
                    <v-row>
                      <v-col cols="12" md="5" style="border-right:1px solid">
                          <v-text-field
                          v-model="document"
                          label="Document"
                          disabled
                          required
                          ></v-text-field>
                          <v-menu
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="date"
                                label="Ducument Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                          <v-autocomplete
                          v-model="branch"
                          :items="branchs"
                          chips
                          :rules="nameRules"
                          label="From Branch"
                          item-text="name"
                          item-value="id"
                          >
                            <template v-slot:selection="data">
                              <v-chip
                                v-bind="data.attrs"
                                style="color:white"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                :color="color"
                                @click:close="remove('branch')"
                              >
                                <v-avatar left>
                                  <v-img :src="img('branch',data.item.image_link)"></v-img>
                                </v-avatar>
                                {{ data.item.name }}
                              </v-chip>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-avatar>
                                  <img :src="img('branch',data.item.image_link)">
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title class="custom-list-item-title-modal" v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                          <v-text-field
                            v-model="note"
                            label="Note"
                          ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="7">
                        <v-list-item>
                        <v-list-item-avatar>

                        </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="custom-list-item-title-modal" >Total</v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip
                              class="ma-2"
                              color="primary"
                            >
                            {{total_belanja | numeral('0,0')}}
                            </v-chip>
                          </v-list-item-action>
                        </v-list-item>
                        <hr>
                          <v-list-item
                            v-for="(item) in cart"
                            :key="item.id"
                          >
                          <v-list-item-avatar>
                           <v-btn icon small rounded depressed @click="dialogRemoveItemCart(item.id)">
                                <span  style="color:red">X</span>
                            </v-btn>
                          </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="custom-list-item-title-modal" v-text="item.name"></v-list-item-title>
                              <v-list-item-subtitle class="custom-list-item-subtitle-modal" > {{item.qty}}</v-list-item-subtitle>

                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon small rounded depressed @click="setQuantity({action:'removeQuantity',id:item.id})" :disabled="item.qty <= 1">
                                    <v-icon dark color="error">remove_circle</v-icon>
                              </v-btn>

                            </v-list-item-action>
                            <v-list-item-action class="text-center">
			                        <input type="number" v-model="item.qty" min="1" @keypress="isNumber($event)" style="width:60px; border-bottom:1px solid black" text-xs-center>
                            </v-list-item-action>
                            <v-list-item-action style="margin-left:0px !important">
                                  <v-btn icon small rounded depressed @click="setQuantity({action:'addQuantity',id:item.id})" :key="item.id">
                                    <v-icon dark color="success">add_circle</v-icon>
                                  </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                      </v-col>
                    </v-row>
               </v-form>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            class="custom-modal-title"
            @click="close"
          >
            Cancel
          </v-btn>

           <v-btn
            color="success"
            tile
            :disabled="!valid || !cart.length"
            @click="edit_data ? update() : save()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <ProductCompnent v-on:close="openProduct" v-if="dialog_product"/>
    <dialogDelete :dialog="dialog_remove_item" data="Item" v-on:close="dialogRemoveItemCart" v-on:deleteAction="removeItem"/>

  </div>

</template>

<script>
import dialogDelete from '../../../../components/external/DialogDelete'
import { mapActions, mapGetters } from 'vuex'
import ProductCompnent from './product'
import GlobalMixin from '../../../mixin/index'
export default {
  mixins:[GlobalMixin],
  components:{
    ProductCompnent,
    dialogDelete
  },
  methods: {
    ...mapActions({
      setData:'data/setData',
      setQuantity:'cart/setQuantity',
      setCart:'cart/setCart',
    }),

    async send_data() {
      let cart = await this.cart.map(x => {
        var data_cart = {
          base_price: parseInt(x.base_price),
          begin_price: parseInt(x.begin_price),
          price: parseInt(x.base_price),
          product_id: x.id,
          qty: x.qty,
        }
        return data_cart
      })
      const data = {
        from_branch_id: this.branch,
        branch_id: this.branch,
        to_branch_id:this.branch,
        company_id: this.user.company_id,
        products: cart,
        discount: 0,
        doc_date: this.date,
        doc_no: this.document,
        doc_type: "OUT",
        entry_by: this.user.fullname,
        note: this.note,
      }
      return data
    },
    async save () {
      this.loading = true
      const data = JSON.stringify(await this.send_data())
      await this.axios.post('v1/inventory/out/create',data,{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Accept' :'application/json',
        }
      })
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success submit data',
            color_snakbar: 'success'
          })
          await this.go()
          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },

    remove(data) {
      this[data] = ''
    },
    async update() {
      const data = JSON.stringify(await this.send_data())
      await this.axios.put('v1/inventory/out/update/' + this.edit_data.id,data,{
        headers: {
          // Overwrite Axios's automatically set Content-Type
          'Content-Type': 'application/json',
          'Accept' :'application/json',
        }
      })
        .then(async (ress) => {
          this.setSnakbar({
            status: true,
            pesan: 'Success update data',
            color_snakbar: 'success'
          })
          await this.go()

          this.close()
        })
        .catch((err) => console.log(err.response))
      this.loading = false
    },
    close () {
      this.reset()
      this.setCart([])
      this.$emit('close')
    },
    go() {
      this.$emit('go')
    },
    dialogRemoveItemCart(id_data = null) {
      this.id_remove_item = id_data
      this.dialog_remove_item =!this.dialog_remove_item
    },
    removeItem() {
      this.setQuantity({
        action:'removeItem',
        id:this.id_remove_item
      })
      this.dialogRemoveItemCart()
    },
    reset () {
       this.note = ''
      this.name = ''
      this.branch = ''
      this.document = ''
       this.date = new Date().toISOString().substr(0, 10)
    },
    img: function (folder,file) {
      if (file !== null) {
         return process.env.VUE_APP_IMGLINK + `${folder}/` + file
      } else {
        return this.imgurl
      }

    },
    openProduct() {
      this.dialog_product = !this.dialog_product
    },

    makeid(length = 5) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },

    async getDetail(id) {
        this.progress = true
       await this.axios.get('v1/inventory/in/details/' + id)
      .then(async (ress) => {
         let cart = await ress.data.data.map( x => {
          let product = {
            id:x.product_id,
            qty: parseInt(x.qty),
            pesan: parseInt(x.qty),
            base_price:parseInt(x.product.base_price),
            name:x.product.name,
            checkhed:true
          }
          return product
        })
        this.setCart(cart)
      })
      .catch((err) => console.log(err.response))
      this.progress = false
    }
  },

  data: () => ({
    valid: true,
    lazy: false,
    loading: false,
    dialog_product:false,
    dialog_remove_item:false,
    id_remove_item:'',
    progress:false,
    name: '',
    note: '',
    branch: '',
    payment: '',
    vendor: '',
    document:'',
    imgurl: 'img/no_img.png',
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    nameRules: [
      v => !!v || 'this field required'
    ],
  }),

  computed: {
    ...mapGetters({
      branchs: 'data/branchs',
      cart:'cart/cart',
      edit_data:'cart/edit_data'

    }),
    total_belanja() {
      let total = 0;
      this.cart.forEach(element => {
        total = total + (element.qty * element.base_price)
      });
      return total
    }

  },

  async created () {

     if (this.edit_data) {
       this.note = this.edit_data.note
          this.name = this.edit_data.name
          this.branch = this.edit_data.from_branch_id
          this.document = this.edit_data.doc_no
          await this.getDetail(this.edit_data.id)
     this.date = new Date(this.edit_data.doc_date).toISOString().substr(0, 10)
          console.log(this.edit_data)
     } else {
    this.document = "DINV-OUT"+new Date().getFullYear() + this.makeid()

     }
          await this.get_data_master('branch','branchs')

  }
}
</script>
